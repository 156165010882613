import React, {
  type ReactNode,
  type FC,
} from 'react';

import useStyles from './SnapScrollContainer.styles';

interface SnapScrollContainerProps {
  children: ReactNode
  className?: string
}

const SnapScrollContainer: FC<SnapScrollContainerProps> = ({
  children,
  className,
  ...props
}) => {
  const { cx, classes } = useStyles();

  const childrenArray = React.Children.toArray(children);

  return (
    <div className={cx(classes.snapScrollParent, className)} {...props}>
      {childrenArray.map((element, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={cx(classes.snapScrollChild, 'snapScrollChild')} key={index}>
          {element}
        </div>
      ))}
    </div>
  );
};

export default SnapScrollContainer;
