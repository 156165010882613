import translations from 'locales/en/translations.json';

export const INCLUDES_ITEMS = [
  {
    label: 'unispin',
    imgSrc: '/images/package-section/icon-unispin.svg',
    text: translations.package_section__set_item1,
  },
  {
    label: 'plates',
    imgSrc: '/images/package-section/icon-plates.svg',
    imgSrcMobile: '/images/package-section/icon-plates-mobile.svg',
    text: translations.package_section__set_item2,
    textMobile: translations.package_section__set_item2_mobile,
  },
  {
    label: 'tweezers',
    imgSrc: '/images/package-section/icon-tweezers.svg',
    text: translations.package_section__set_item3,
  },
  {
    label: 'bearing',
    imgSrc: '/images/package-section/icon-bearing.svg',
    text: translations.package_section__set_item4,
  },
  {
    label: 'key',
    imgSrc: '/images/package-section/icon-key.svg',
    text: translations.package_section__set_item5,
  },
];
