import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  floatingBannerRoot: {
    position: 'fixed',
    bottom: 60,
    left: '50%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRadius: 10,
    transform: 'translateX(-50%)',
    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    scale: 1,
    opacity: 1,
    visibility: 'visible',
    transition: '0.5s ease',
    transformOrigin: 'left center',
    zIndex: 100,

    [theme.breakpoints.down(1980)]: {
      bottom: 20,
    },

    [theme.breakpoints.down('1024')]: {
      height: 50,
    },

    '&.hidden': {
      opacity: 0,
      visibility: 'hidden',
      scale: 0,
    },
  },
  floatingBannerPrice: {
    marginRight: 20,
    fontSize: 30,
    fontWeight: 800,
    color: 'black',

    [theme.breakpoints.down('1600')]: {
      fontSize: 24,
    },

    [theme.breakpoints.down('768')]: {
      marginRight: 10,
      fontSize: 20,
    },

    [theme.breakpoints.down(385)]: {
      display: 'none',
    },
  },
  floatingBannerText: {
    marginRight: 50,
    fontSize: 16,
    fontWeight: 500,
    color: theme.colors.brand.gray450,

    [theme.breakpoints.down('1600')]: {
      marginRight: 20,
      fontSize: 14,
    },

    [theme.breakpoints.down('768')]: {
      fontSize: 12,
    },
  },
  floatingBannerButton: {
    width: 250,
    height: 40,
    display: 'grid',
    placeItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.64,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    backgroundColor: 'black',
    borderRadius: 5,

    [theme.breakpoints.down('1600')]: {
      width: 230,
    },

    [theme.breakpoints.down('768')]: {
      width: 150,
    },
  },
});

export default useStyles;
