import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  secureRoot: {
    [theme.breakpoints.down('768')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  secureLeftContainer: {
    width: 'max-content',
    maxHeight: 500,

    [theme.breakpoints.down('1400')]: {
      width: 'unset',
    },

    [theme.breakpoints.down('768')]: {
      maxHeight: 'unset',
    },

    '& .title': {
      [theme.breakpoints.down('768')]: {
        padding: '0 16px',
      },
    },

    '& .text': {
      maxWidth: 570,

      [theme.breakpoints.down('768')]: {
        padding: '0 16px',
      },
    },

    '& .image': {
      width: 'clamp(16.875rem, 13.446rem + 7.143vw, 22.375rem)',
      marginTop: 80,

      [theme.breakpoints.down('1600')]: {
        marginTop: 60,
      },

      [theme.breakpoints.down('1400')]: {
        marginTop: 40,
      },
    },
  },
  securePlayerContainer: {
    [theme.breakpoints.down('768')]: {
      display: 'grid',
      placeItems: 'center',
      overflow: 'hidden',
    },

    [theme.breakpoints.down(400)]: {
      maxWidth: '100vw',
    },

    '& .playerWrapper': {
      position: 'relative',
      width: 'clamp(44.375rem, 13.8393rem + 33.9286vw, 56.25rem) !important',
      height: 'clamp(44.375rem, 13.8393rem + 33.9286vw, 56.25rem) !important',

      [theme.breakpoints.down('1400')]: {
        aspectRatio: 1,
        width: '49.306vw !important',
        height: 'auto !important',
      },

      [theme.breakpoints.down('768')]: {
        width: '456px !important',
        height: '440px !important',
        marginInline: -32,
      },

      [theme.breakpoints.down(400)]: {
        marginInline: -82,
      },
    },
  },
  securePlayer: {
    position: 'absolute',
    inset: 0,
  },
});

export default useStyles;
