import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  overviewInfoContainer: {
    width: 'max-content',
    maxHeight: 500,

    [theme.breakpoints.down('1400')]: {
      width: 'unset',
    },

    [theme.breakpoints.down('768')]: {
      maxHeight: 'unset',
    },

    '& .text': {
      maxWidth: 570,

      '&:nth-of-type(2)': {
        marginTop: 50,

        [theme.breakpoints.down('1400')]: {
          marginTop: 30,
        },

        [theme.breakpoints.down('768')]: {
          marginTop: 0,
        },
      },

      '&:nth-of-type(3)': {
        [theme.breakpoints.down('768')]: {
          marginTop: 20,
        },
      },
    },

    '& .image': {
      height: 'auto',
      marginTop: 20,

      '&:nth-of-type(1)': {
        width: 'clamp(14.25rem, 12.406rem + 7.125vw, 21.313rem)',
      },

      '&:nth-of-type(2)': {
        width: 'clamp(7.813rem, 6.785rem + 3.972vw, 11.75rem)',
      },
    },
  },
  overviewPlayerContainer: {
    position: 'relative',
    width: 'clamp(41.75rem, 4.625rem + 41.25vw, 56.1875rem) !important',
    height: 'clamp(43.8125rem, 4.5982rem + 43.5714vw, 59.0625rem) !important',

    [theme.breakpoints.down('1400')]: {
      aspectRatio: 1,
      width: '46.389vw !important',
      height: 'auto !important',
    },

    [theme.breakpoints.down('768')]: {
      width: '100% !important',
    },
  },
  overviewPlayer: {
    position: 'absolute',
    inset: 0,
  },
});

export default useStyles;
