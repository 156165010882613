import { type FC } from 'react';
import Markdown from 'react-markdown';

import useStyles from './SectionTitle.styles';

type HeadingTag = Extract<keyof JSX.IntrinsicElements, `h${1 | 2 | 3 | 4 | 5 | 6}`>;

interface SectionTitleProps {
  headingType: HeadingTag
  textColor?: string,
  headingSize?: 'medium' | 'small',
  className?: string,
  children: string,
}

const SectionTitle: FC<SectionTitleProps> = ({
  headingType: Tag = 'h2',
  textColor = 'white',
  headingSize,
  className,
  children,
}) => {
  const { cx, classes } = useStyles({ textColor });

  return (
    <Tag className={cx(
      classes.sectionTitle,
      headingSize && headingSize,
      className,
    )}
    >
      <Markdown>
        {children}
      </Markdown>
    </Tag>
  );
};

export default SectionTitle;
