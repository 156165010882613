import {
  UNICORN_HUNTERS_URL,
  UNICORN_STORE_URL,
  UNICORN_NEWS_URL,
  UNICOIN_URL,
} from 'constants/links';
import translations from 'locales/en/translations.json';

interface HeaderNav {
  label: string,
  link?: string,
  isDropdown: boolean,
  dropdownOptions?: {
    dropdownLabel: string,
    dropdownLink: string,
  }[]
}

export const HEADER_NAV_MENU: HeaderNav[] = [
  {
    label: translations.header__nav_label_1,
    link: 'preview',
    isDropdown: false,
  },
  {
    label: translations.header__nav_label_2,
    link: 'overview',
    isDropdown: false,
  },
  {
    label: translations.header__nav_label_3,
    link: 'highlights',
    isDropdown: false,
  },
  {
    label: translations.header__nav_label_4,
    link: 'package',
    isDropdown: false,
  },
  {
    label: translations.header__nav_label_5,
    link: 'preorder',
    isDropdown: false,
  },
  {
    label: translations.header__nav_dropdown,
    isDropdown: true,
    dropdownOptions: [
      {
        dropdownLabel: translations.header__nav_dropdown_label_1,
        dropdownLink: UNICOIN_URL,
      },
      {
        dropdownLabel: translations.header__nav_dropdown_label_2,
        dropdownLink: UNICORN_HUNTERS_URL,
      },
      {
        dropdownLabel: translations.header__nav_dropdown_label_3,
        dropdownLink: UNICORN_NEWS_URL,
      },
      {
        dropdownLabel: translations.header__nav_dropdown_label_4,
        dropdownLink: UNICORN_STORE_URL,
      },
    ],
  },
];
