import { FC, ReactNode } from 'react';
import useStyles from './ContentLayout';

interface ContentLayoutProps {
  children: ReactNode
  classNames?: {
    root?: string,
    content?: string,
  }
  isTwoColumns?: boolean
  id?: string
}

const ContentLayout: FC<ContentLayoutProps> = ({
  children,
  classNames,
  isTwoColumns = true,
  id,
}) => {
  const { cx, classes } = useStyles();

  return (
    <section className={cx(classes.contentRoot, classNames?.root)} id={id}>
      <div className={cx(
        classes.contentContent,
        isTwoColumns && 'twoColumns',
        classNames?.content,
      )}
      >
        {children}
      </div>
    </section>
  );
};

export default ContentLayout;
