import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  contentRoot: {
    position: 'relative',
    height: '100vh',
    padding: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',

    [theme.breakpoints.down('1600')]: {
      padding: '100px 50px',
    },

    [theme.breakpoints.down('768')]: {
      height: 'unset',
      padding: '50px 16px',
    },
  },
  contentContent: {
    maxWidth: 1800,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 0,

    [theme.breakpoints.down(2100)]: {
      maxWidth: 1100,
    },

    '&.twoColumns': {
      gridTemplateColumns: '1fr max-content',
      alignItems: 'center',

      [theme.breakpoints.down('768')]: {
        gridTemplateColumns: '1fr',
      },
    },

    [theme.breakpoints.down('768')]: {
      padding: 0,
      gridTemplateColumns: '1fr',
      gap: 20,
    },
  },
});

export default useStyles;
