import { type FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'usehooks-ts';

import { theme } from 'styles/theme';

import ContentLayout from 'components/layouts/ContentLayout';
import { SectionParagraph, SectionTitle } from 'components/ui';

import translations from 'locales/en/translations.json';

import useStyles from './SecureSection.styles';

const secureVideoUrl = 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/f9ac41c6abfe233cbdea6e2e4f08cf81/manifest/video.m3u8';

interface SecureSectionProps {
  id?: string
}

const SecureSection: FC<SecureSectionProps> = ({ id }) => {
  const { classes } = useStyles();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('768', true));

  return (
    <ContentLayout id={id} classNames={{ root: classes.secureRoot }}>
      <div className={classes.secureLeftContainer}>
        <SectionTitle
          className="title"
          headingSize="medium"
          headingType="h2"
        >
          {translations.secure_section__title}
        </SectionTitle>

        {isMobile && (
          <div className={classes.securePlayerContainer}>
            <div className="playerWrapper">
              <ReactPlayer
                className={classes.securePlayer}
                url={secureVideoUrl}
                width="100%"
                height="100%"
                muted
                loop
                playing
                playsinline
                controls={false}
                onBufferEnd={() => setIsVideoLoaded(true)}
              />

              {!isVideoLoaded && (
                <img
                  width="100%"
                  height="100%"
                  className={classes.securePlayer}
                  src="images/secure-section/video-thumbnail.webp"
                  alt="tighten the screw"
                />
              )}
            </div>
          </div>
        )}

        <SectionParagraph className="text">
          {translations.secure_section__paragraph}
        </SectionParagraph>

        {!isMobile && (
          <img
            className="image"
            src="images/secure-section/secure.svg"
            alt={translations.secure_section__title}
          />
        )}
      </div>

      {!isMobile && (
        <div className={classes.securePlayerContainer}>
          <div className="playerWrapper">
            <ReactPlayer
              className={classes.securePlayer}
              url={secureVideoUrl}
              width="100%"
              height="100%"
              muted
              loop
              playing
              playsinline
              controls={false}
              onBufferEnd={() => setIsVideoLoaded(true)}
            />

            {!isVideoLoaded && (
              <img
                width="100%"
                height="100%"
                className={classes.securePlayer}
                src="images/secure-section/video-thumbnail.webp"
                alt="tighten the screw"
              />
            )}
          </div>
        </div>
      )}
    </ContentLayout>
  );
};

export default SecureSection;
