import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss
  .withParams<{ textColor: string }>()
  .create(({ textColor }) => ({
    sectionTitle: {
      marginBottom: 60,
      fontSize: 'clamp(4.375rem, 1.161rem + 3.571vw, 5.625rem)',
      fontWeight: 900,
      lineHeight: 1.2,
      color: textColor,
      textTransform: 'uppercase',
      letterSpacing: 4,
      whiteSpace: 'pre-line',

      [theme.breakpoints.down('1200')]: {
        marginBottom: 30,
        fontSize: 'clamp(3.125rem, 2.467rem + 2.545vw, 4.375rem)',
      },

      [theme.breakpoints.down('768')]: {
        marginBottom: 0,
      },

      '&.medium': {
        fontSize: 'clamp(3.75rem, -1.071rem + 5.357vw, 5.625rem)',

        [theme.breakpoints.down('1200')]: {
          fontSize: 'clamp(2.5rem, 1.842rem + 2.545vw, 3.75rem)',
        },
      },
    },
  }));

export default useStyles;
