import { type FC } from 'react';
import Markdown from 'react-markdown';

import { theme } from 'styles/theme';

import useStyles from './SectionParagraph.styles';

interface SectionParagraphProps {
  textColor?: string,
  className?: string,
  children: string,
}

const SectionParagraph: FC<SectionParagraphProps> = ({
  textColor = theme.colors.brand.gray400,
  className,
  children,
}) => {
  const { cx, classes } = useStyles({ textColor });

  return (
    <Markdown className={cx(classes.sectionParagraph, className)}>
      {children}
    </Markdown>
  );
};

export default SectionParagraph;
