import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  insertRoot: {
    [theme.breakpoints.down('768')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  insertInfoContainer: {
    width: 'max-content',
    maxHeight: 500,

    [theme.breakpoints.down('1400')]: {
      width: 'unset',
    },

    [theme.breakpoints.down('768')]: {
      width: '100%',
      maxHeight: 'unset',
    },

    '& .title': {
      [theme.breakpoints.down('768')]: {
        padding: '0 16px',
      },
    },

    '& .text': {
      maxWidth: 547,

      [theme.breakpoints.down('768')]: {
        padding: '0 16px',
      },

    },

    '& .image': {
      width: 'clamp(20rem, 14.701rem + 11.039vw, 28.5rem)',
      marginTop: 80,

      [theme.breakpoints.down('1600')]: {
        marginTop: 60,
      },

      [theme.breakpoints.down('1400')]: {
        marginTop: 40,
      },
    },
  },
  insertPlayerContainer: {
    [theme.breakpoints.down('768')]: {
      display: 'grid',
      placeItems: 'center',
      overflow: 'hidden',
    },

    [theme.breakpoints.down(400)]: {
      maxWidth: '100vw',
    },

    '& .playerWrapper': {
      position: 'relative',
      width: 'clamp(37.5rem, 8.7321rem + 31.9643vw, 48.6875rem) !important',
      height: 'clamp(36.125rem, 8.4821rem + 30.7143vw, 46.875rem) !important',

      [theme.breakpoints.down('1400')]: {
        aspectRatio: 1,
        width: '41.667vw !important',
        height: 'auto !important',
      },

      [theme.breakpoints.down('768')]: {
        width: '456px !important',
        height: '440px !important',
        marginInline: -32,
      },

      [theme.breakpoints.down(400)]: {
        marginInline: -82,
      },
    },
  },
  insertPlayer: {
    position: 'absolute',
    inset: 0,

    '& video': {
      marginBottom: 'auto',
    },
  },
});

export default useStyles;
