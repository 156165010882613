import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  preorderRoot: {
    minHeight: '100vh',
    height: 'unset',
    paddingTop: 160,
    paddingBottom: 160,

    [theme.breakpoints.down('768')]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  preorderContent: {
    maxWidth: 1100,
    padding: 0,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '0 clamp(6.25rem, 3.133rem + 6.494vw, 11.25rem)',

    [theme.breakpoints.down('768')]: {
      gridTemplateColumns: '1fr',
    },
  },
  preorderTitle: {
    gridColumn: '1/ span 2',
    marginBottom: 100,

    [theme.breakpoints.down('768')]: {
      marginBottom: 60,
      gridColumn: 'unset',
    },
  },
  preorderTextBlock: {
    maxWidth: 440,
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    color: 'white',

    '& .priceContainer': {
      display: 'flex',
      alignItems: 'center',
      gap: 20,

      '& .price': {
        fontSize: 'clamp(2.75rem, 0.1786rem + 2.8571vw, 3.75rem)',
        fontWeight: 800,

        [theme.breakpoints.down('1200')]: {
          fontSize: 'clamp(1.875rem, 1.4141rem + 1.7812vw, 2.75rem)',
        },
      },

      '& .mobileTeaserWrapper': {
        '& .mobileTeaser': {
          display: 'none',
          backgroundImage: 'linear-gradient(90deg, #9B7842 0%, #A4B599 100%)',
          color: 'transparent',
          backgroundClip: 'text',

          [theme.breakpoints.down('768')]: {
            display: 'inline-block',
          },
        },
      },
    },

    '& .subtitle': {
      fontSize: 'clamp(1.5rem, 0.5357rem + 1.0714vw, 1.875rem)',
      fontWeight: 500,
      lineHeight: 1.3,
      whiteSpace: 'pre-line',

      [theme.breakpoints.down('768')]: {
        display: 'none',
      },
    },
  },
  preorderForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,

    [theme.breakpoints.down('768')]: {
      marginTop: 40,
      gap: 30,
    },
  },
  preorderTextInput: {
    width: '100%',
    height: 60,
    padding: '0 20px',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 20,
    fontWeight: 300,
    color: 'white',
    backgroundColor: theme.colors.brand.blue950,
    border: '1px solid #333D48',
    borderRadius: 5,
    outline: 'none',

    '&::placeholder': {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 20,
      fontWeight: 300,
      color: `${theme.colors.common.white}99`,
    },
  },
  preorderCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& .privacyPolicyLink': {
      fontSize: 20,
      fontWeight: 500,
      color: 'white',
      textDecoration: 'underline #FCD144',

      [theme.breakpoints.down('360')]: {
        fontSize: 18,
      },
    },
  },
  preorderCheckboxContainer: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    cursor: 'pointer',

    '& .checkbox': {
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.brand.blue950,
      border: '1px solid #333D48',
      borderRadius: 5,
      transition: 'all 0.2s',

      '& .checkmark': {
        width: 19,
        height: 19,
        fill: 'none',
        stroke: theme.colors.brand.yellow,
        strokeWidth: 3,
        strokeDasharray: 22,
        strokeDashoffset: 66,
        transition: 'all 0.2s',
      },
    },

    '& .label': {
      fontSize: 20,
      fontWeight: 300,
      color: `${theme.colors.common.white}99`,

      [theme.breakpoints.down('1200')]: {
        fontSize: 18,
      },
    },

    '&[data-selected] .checkmark': {
      strokeDashoffset: 44,
    },
  },
  preorderButton: {
    width: '100%',
    height: 60,
    marginTop: 20,
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: 0.64,
    color: 'black',
    backgroundColor: theme.colors.brand.yellow,
    border: 'none',
    borderRadius: 5,
    outline: 'none',
    transition: '0.4s ease',

    '&:disabled': {
      backgroundColor: theme.colors.brand.blue900,
      pointerEvents: 'none',
    },

    '&.success': {
      backgroundColor: theme.colors.status.success,
    },

    '&.failed': {
      backgroundColor: theme.colors.status.error,
    },
  },
  preorderFooter: {
    gridColumn: '1/ span 2',
    width: '100%',
    marginTop: 160,
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    alignItems: 'center',

    [theme.breakpoints.down('768')]: {
      marginTop: 90,
      gridColumn: 'unset',
    },

    '& .copyright': {
      color: 'white',
      opacity: 0.4,

      [theme.breakpoints.down('768')]: {
        fontSize: 12,
      },
    },

    '& .socialLinks': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 40,
      alignItems: 'center',

      [theme.breakpoints.down('768')]: {
        justifyContent: 'center',
        gap: 24,
      },

      '& a': {
        display: 'grid',
        placeItems: 'center',
      },

      '& img': {
        [theme.breakpoints.down('768')]: {
          maxWidth: 23,
        },
      },
    },
  },
});

export default useStyles;
