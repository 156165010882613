import {
  UNICOIN_FACEBOOK,
  UNICOIN_INSTAGRAM,
  UNICOIN_LINKEDIN,
  UNICOIN_TELEGRAM,
  UNICOIN_TIKTOK,
  UNICOIN_VIMEO,
  UNICOIN_X,
  UNICOIN_YOUTUBE,
} from 'constants/links';

export const SOCIALS = [
  {
    label: 'facebook',
    link: UNICOIN_FACEBOOK,
    logoSrc: 'images/socials/facebook.svg',
  },
  {
    label: 'x',
    link: UNICOIN_X,
    logoSrc: 'images/socials/x.svg',
  },
  {
    label: 'linkedin',
    link: UNICOIN_LINKEDIN,
    logoSrc: 'images/socials/linkedin.svg',
  },
  {
    label: 'instagram',
    link: UNICOIN_INSTAGRAM,
    logoSrc: 'images/socials/instagram.svg',
  },
  {
    label: 'vimeo',
    link: UNICOIN_VIMEO,
    logoSrc: 'images/socials/vimeo.svg',
  },
  {
    label: 'youtube',
    link: UNICOIN_YOUTUBE,
    logoSrc: 'images/socials/youtube.svg',
  },
  {
    label: 'tiktok',
    link: UNICOIN_TIKTOK,
    logoSrc: 'images/socials/tiktok.svg',
  },
  {
    label: 'telegram',
    link: UNICOIN_TELEGRAM,
    logoSrc: 'images/socials/telegram.svg',
  },
];
