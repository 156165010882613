import {
  type FC,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { HEADER_NAV_MENU } from '../../Header.constants';

import useStyles from './BurgerMenu.styles';

interface BurgerMenuProps {
  isOpen: boolean
  scrollTo: (sectionId: string) => void
  handleOpen: Dispatch<SetStateAction<boolean>>
}

const BurgerMenu: FC<BurgerMenuProps> = ({
  isOpen,
  scrollTo,
  handleOpen,
}) => {
  const { cx, classes } = useStyles();

  const handleClick = (link: string) => {
    handleOpen(false);
    scrollTo(link);
  };

  return (
    <div className={cx(classes.burgerMenuRoot, isOpen && 'open')}>
      <div className="contentWrapper">
        <nav className="navigationMenu">
          {HEADER_NAV_MENU.map(({
            label,
            link = '',
            isDropdown,
            dropdownOptions,
          }) => (
            isDropdown && dropdownOptions ? (
              <div className={classes.burgerMenuEcosystemContainer} key={label}>
                <p className="title">
                  {label}
                </p>

                <ul className="list">
                  {dropdownOptions.map(({ dropdownLabel, dropdownLink }) => (
                    <li key={dropdownLink}>
                      <a
                        className="link"
                        href={dropdownLink}
                        target="_blank"
                        rel="noreferrer"
                      >

                        {dropdownLabel}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <button
                className={classes.burgerNavButton}
                key={label}
                onClick={() => handleClick(link)}
                type="button"
              >
                {label}
              </button>
            )
          ))}
        </nav>
      </div>
    </div>
  );
};

export default BurgerMenu;
