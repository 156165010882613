import { type FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'usehooks-ts';

import { theme } from 'styles/theme';

import ContentLayout from 'components/layouts/ContentLayout';
import { SectionParagraph, SectionTitle } from 'components/ui';

import translations from 'locales/en/translations.json';

import useStyles from './SealSection.styles';

const sealVideoUrl = 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/11f70f559b4fa2737444be59943deaf6/manifest/video.m3u8';

interface SealSectionProps {
  id?: string
}

const SealSection: FC<SealSectionProps> = ({ id }) => {
  const { classes } = useStyles();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('768', true));

  return (
    <ContentLayout id={id} classNames={{ root: classes.sealRoot }}>
      <div className={classes.sealLeftContainer}>
        <SectionTitle
          className="title"
          headingSize="medium"
          headingType="h2"
        >
          {translations.seal_section__title}
        </SectionTitle>

        {isMobile && (
          <div className={classes.sealPlayerContainer}>
            <div className="playerWrapper">
              <ReactPlayer
                className={classes.sealPlayer}
                url={sealVideoUrl}
                width="100%"
                height="100%"
                muted
                loop
                playing
                playsinline
                controls={false}
                onBufferEnd={() => setIsVideoLoaded(true)}
              />

              {!isVideoLoaded && (
                <img
                  width="100%"
                  height="100%"
                  className={classes.sealPlayer}
                  src="images/seal-section/video-thumbnail.webp"
                  alt="close the coin"
                />
              )}
            </div>
          </div>
        )}

        <SectionParagraph className="text">
          {translations.seal_section__paragraph}
        </SectionParagraph>

        {!isMobile && (
          <img
            className="image"
            src="images/seal-section/seal.svg"
            alt={translations.seal_section__title}
          />
        )}
      </div>

      {!isMobile && (
        <div className={classes.sealPlayerContainer}>
          <div className="playerWrapper">
            <ReactPlayer
              className={classes.sealPlayer}
              url={sealVideoUrl}
              width="100%"
              height="100%"
              muted
              loop
              playing
              playsinline
              controls={false}
              onBufferEnd={() => setIsVideoLoaded(true)}
            />

            {!isVideoLoaded && (
              <img
                width="100%"
                height="100%"
                className={classes.sealPlayer}
                src="images/seal-section/video-thumbnail.webp"
                alt="close the coin"
              />
            )}
          </div>
        </div>
      )}
    </ContentLayout>
  );
};

export default SealSection;
