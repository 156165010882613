import {
  type FC,
  useEffect,
  useState,
} from 'react';

import { useDebounceCallback } from 'usehooks-ts';

import { STEPS } from './StepsOverlay.constants';

import useStyles from './StepsOverlay.styles';

const StepsOverlay: FC = () => {
  const { cx, classes } = useStyles();

  const [activeStep, setActiveStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);

  const debouncedSteps = useDebounceCallback(setActiveStep, 400);

  const scrollTo = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) section.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const sections = STEPS.map((step) => document.getElementById(step.id));

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        const currentStep = STEPS.find((step) => step.id === entry.target.id);

        if (!isVisible) setIsVisible(true);

        if (currentStep) debouncedSteps(currentStep.stepNumber);
      }
    }, { threshold: 0.2 });

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const sections = [document.getElementById('preview'), document.getElementById('highlights')];

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        setIsVisible(false);
      }
    }, { threshold: 0.2 });

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={cx(classes.stepsOverlayRoot, isVisible && 'visible')}>
      {STEPS.map(({ stepNumber, id }) => (
        <button
          className={cx(classes.stepsOverlayStep, stepNumber === activeStep ? 'active' : 'inactive')}
          key={id}
          type="button"
          onClick={() => scrollTo(id)}
        >
          {stepNumber}
          <span className="step">
          &nbsp;step
          </span>
        </button>
      ))}
    </div>
  );
};

export default StepsOverlay;
