export const STEPS = [
  {
    stepNumber: 4,
    id: 'secure',
  },
  {
    stepNumber: 3,
    id: 'seal',
  },
  {
    stepNumber: 2,
    id: 'insert',
  },
  {
    stepNumber: 1,
    id: 'overview',
  },
];
