import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  headerWrapper: {
    position: 'absolute',
    inset: 0,

    '&::after': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundColor: theme.colors.common.black,
      visibility: 'hidden',
      opacity: 0,
      transition: '0.4s ease',
      zIndex: 200,
    },

    '&.burgerActive': {
      '&::after': {
        visibility: 'visible',
        opacity: 0.8,
      },
    },
  },
  headerRoot: {
    position: 'fixed',
    top: 20,
    left: '50%',
    maxWidth: 1800,
    width: 'calc(100% - 100px)',
    height: 60,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.common.white,
    borderRadius: 10,
    transform: 'translateX(-50%)',
    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    transition: '0.4s ease',
    zIndex: 250,

    '@media screen and (max-width: 960px)': {
      width: 'calc(100% - 40px)',
      height: 50,
    },

    '@media screen and (max-width: 680px)': {
      width: 'calc(100% - 32px)',
    },

    '&.burgerActive': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  headerLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '@media screen and (max-width: 960px)': {
      gap: 0,
    },

    '& .logo': {
      width: 'clamp(3rem, 2.844rem + 0.325vw, 3.25rem)',
      height: 'auto',
    },

    '& .logoText': {
      width: 'clamp(4.938rem, 3.847rem + 2.273vw, 6.688rem)',
      height: 'auto',
    },
  },
  headerNav: {
    height: '100%',
    display: 'flex',
    gap: 40,
    alignItems: 'center',

    [theme.breakpoints.down('1400')]: {
      gap: 20,
    },

    [theme.breakpoints.down('1200')]: {
      display: 'none',
    },

    '& .navItem': {
      fontSize: 16,
      fontWeight: 400,
      color: 'black',
      textDecoration: 'none',
    },
  },
  headerDropdownButton: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 6,

    '&:hover': {
      '& .arrowIcon': {
        transform: 'rotate(180deg)',
      },

      '& .dropdownList': {
        visibility: 'visible',
        opacity: 1,
      },
    },

    '& .arrowIcon': {
      transition: '0.2s ease',
    },

    '& .dropdownList': {
      position: 'absolute',
      top: '100%',
      left: '50%',
      width: 210,
      padding: '16px 0',
      backgroundColor: theme.colors.brand.gray100,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      boxShadow: '0px 20px 18px 0px rgba(0,0,0,0.1)',
      visibility: 'hidden',
      opacity: 0,
      transition: '0.2s ease',
      transform: 'translateX(-50%)',

      '& .dropdownItem': {
        width: '100%',
        height: '100%',
        padding: '14px 18px',
        display: 'inline-block',
        textAlign: 'left',
        boxSizing: 'border-box',
        opacity: 0.6,
        transition: '0.2s ease',

        '&:hover': {
          color: 'black',
          opacity: 1,
          backgroundColor: 'white',
        },
      },
    },
  },
  headerButton: {
    width: 205,
    height: 40,
    display: 'grid',
    placeItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.64,
    color: 'black',
    textDecoration: 'none',
    textTransform: 'uppercase',
    backgroundColor: theme.colors.brand.yellow,
    borderRadius: 5,

    [theme.breakpoints.down('1200')]: {
      display: 'none',
    },
  },
  headerButtonBurgerMenu: {
    width: 50,
    height: '100%',
    display: 'none',
    placeItems: 'center',

    [theme.breakpoints.down('1200')]: {
      display: 'grid',
    },

    '& .icon': {
      width: 28,
      height: 20,
    },
  },
});

export default useStyles;
