import {
  type FC,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { UNICOIN_URL } from 'constants/links';

import translations from 'locales/en/translations.json';

import { HEADER_NAV_MENU } from './Header.constants';

import BurgerMenu from './components/BurgerMenu';

import useStyles from './Header.styles';

const Header: FC = () => {
  const { cx, classes } = useStyles();

  const headerRootRef = useRef(null);

  const [isBurgerMenu, setIsBurgerMenu] = useState(false);

  const scrollTo = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) section.scrollIntoView({ behavior: 'smooth' });
  };

  useOnClickOutside(headerRootRef, () => setIsBurgerMenu(false));

  return (
    <div className={cx(classes.headerWrapper, isBurgerMenu && 'burgerActive')}>
      <header className={cx(classes.headerRoot, isBurgerMenu && 'burgerActive')} ref={headerRootRef}>
        <button
          className={classes.headerLogoContainer}
          onClick={() => scrollTo('preview')}
          type="button"
        >
          <img
            className="logo"
            src="images/common/unicoin-logo-animate.svg"
            alt="logo"
          />

          <img
            className="logoText"
            src="images/common/unispin-logo-text.svg"
            alt="brand title"
          />
        </button>

        <button
          className={classes.headerButtonBurgerMenu}
          onClick={() => setIsBurgerMenu((prev) => !prev)}
          type="button"
          aria-label="burger menu"
        >
          <img
            className="icon"
            src={isBurgerMenu ? 'images/icons/icon-close.svg' : 'images/icons/burger-menu.svg'}
            alt="burger menu"
          />
        </button>

        <nav className={classes.headerNav}>
          {HEADER_NAV_MENU.map(({
            label,
            link = '',
            isDropdown,
            dropdownOptions,
          }) => (
            isDropdown && dropdownOptions ? (
              <button
                className={cx(classes.headerDropdownButton, 'navItem')}
                key={label}
                type="button"
              >
                {label}

                <img
                  className="arrowIcon"
                  src="images/icons/menu-arrow.svg"
                  alt="ecosystem menu"
                />

                <ul className="dropdownList">
                  {dropdownOptions.map(({ dropdownLabel, dropdownLink }) => (
                    <li key={dropdownLink}>
                      <a
                        className="dropdownItem"
                        href={dropdownLink}
                        target="_blank"
                        rel="noreferrer"
                      >

                        {dropdownLabel}
                      </a>
                    </li>
                  ))}
                </ul>
              </button>
            ) : (
              <button
                className="navItem"
                key={label}
                onClick={() => scrollTo(link)}
                type="button"
              >
                {label}
              </button>
            )
          ))}
        </nav>

        <a
          className={classes.headerButton}
          href={UNICOIN_URL}
          target="_blank"
          rel="noreferrer"
        >
          {translations.header__button}
        </a>

        <BurgerMenu
          isOpen={isBurgerMenu}
          handleOpen={setIsBurgerMenu}
          scrollTo={scrollTo}
        />
      </header>
    </div>
  );
};

export default Header;
