import { type FC, useState } from 'react';
import ReactPlayer from 'react-player';

import translations from 'locales/en/translations.json';

import { SectionTitle } from 'components/ui';

import useStyles from './PreviewSection.styles';

const previewVideoUrl = 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/67b38f0e734e63131465f8ffcb4f65be/manifest/video.m3u8';

interface PreviewSectionProps {
  id?: string,
}

const PreviewSection: FC<PreviewSectionProps> = ({ id }) => {
  const { classes } = useStyles();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <section className={classes.previewRoot} id={id}>
      <ReactPlayer
        className={classes.previewPlayer}
        url={previewVideoUrl}
        muted
        loop
        playsinline
        playing
        controls={false}
        width="100%"
        height="100%"
        onBufferEnd={() => setIsVideoLoaded(true)}
      />

      {!isVideoLoaded && (
        <img
          className={classes.previewPlayerThumbnail}
          src="images/preview-section/thumbnail.webp"
          alt="unispin"
        />
      )}

      <div className={classes.previewTitleContainer}>
        <SectionTitle headingType="h1">
          {translations.preview_section__title}
        </SectionTitle>

        <p className="subtitle">
          {translations.preview_section__subtitle}
        </p>
      </div>
    </section>
  );
};

export default PreviewSection;
