import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  stepsOverlayRoot: {
    position: 'fixed',
    width: 176,
    top: '50%',
    left: 'calc(50px + 23px)',
    padding: '5px 6px',
    display: 'flex',
    gap: 10,
    backgroundColor: theme.colors.brand.blue950,
    borderRadius: 5,
    transform: 'translateY(-150%)',
    rotate: '-90deg',
    transformOrigin: 'center left',
    boxSizing: 'border-box',
    opacity: 0,
    visibility: 'hidden',
    transition: '0.2s ease',
    zIndex: 100,

    '&.visible': {
      transform: 'translateY(-50%)',
      opacity: 1,
      visibility: 'visible',
    },

    [theme.breakpoints.down('1200')]: {
      display: 'none',
    },
  },
  stepsOverlayStep: {
    maxWidth: 'max-content',
    height: 20,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '0.1em',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    color: `${theme.colors.common.white}33`,
    backgroundColor: theme.colors.common.black,
    borderRadius: 5,
    overflow: 'hidden',
    boxSizing: 'border-box',
    transition: '0.2s ease',

    '&.inactive': {
      maxWidth: 20,
    },

    '&.active': {
      padding: '5px 10px',
      color: `${theme.colors.brand.yellow}CC`,

      '& .step': {
        display: 'inline',
        opacity: 1,
        visibility: 'visible',
      },
    },

    '& .step': {
      display: 'none',
      opacity: 0,
      visibility: 'hidden',
      transition: '0.2s ease',
    },
  },
});

export default useStyles;
