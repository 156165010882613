import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  snapScrollParent: {
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    scrollSnapType: 'y mandatory',
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth',

    [theme.breakpoints.down('768')]: {
      height: 'unset',
      overflowY: 'unset',
      scrollSnapType: 'unset',
    },
  },
  snapScrollChild: {
    scrollSnapAlign: 'center',

    [theme.breakpoints.down('768')]: {
      scrollSnapAlign: 'unset',
    },

    '&:has(.scrollSnapAlignStart)': {
      scrollSnapAlign: 'start',
    },
  },
});

export default useStyles;
