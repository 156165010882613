import { tss } from 'tss-react';

const useStyles = tss
  .withParams<{ textColor: string }>()
  .create(({ textColor }) => ({
    sectionParagraph: {
      fontSize: 'clamp(1.125rem, 0.8036rem + 0.3571vw, 1.25rem)',
      fontWeight: 300,
      lineHeight: 1.7,
      color: textColor,
      whiteSpace: 'pre-line',

      '@media screen and (max-width: 1200px)': {
        fontSize: 'clamp(0.875rem, 0.7433rem + 0.5089vw, 1.125rem)',
      },

      '& strong': {
        fontWeight: 500,
        color: 'white',
      },
    },
  }));

export default useStyles;
