export const SLIDES = [
  {
    slideLabel: 'temperature',
    slideText: 'Unispin is made of steel and brass alloy, so it can withstand **temperatures up to 900°C.**',
    slideImgSrc: 'images/highlights-section/swiper/temperature.webp',
    slideVideoUrl: 'https://customer-6ut2ebhjst263mx9.cloudflarestream.com/3bfad7972abb64babad6ddb14a08c916/manifest/video.m3u8',
  },
  {
    slideLabel: 'protects',
    slideText: 'You can see only one letter at a time, which **protects the seed phrase** from a photograph or a casual glance.',
    slideImgSrc: 'images/highlights-section/swiper/protects.webp',
  },
  {
    slideLabel: 'kit',
    slideText: 'Convenient to use. **The kit includes tools** so you can easily assemble Unispin.',
    slideImgSrc: 'images/highlights-section/swiper/kit.webp',
    slideVideoUrl: 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/b693b37c1bf540a0e867b3528d75288a/manifest/video.m3u8',
  },
  {
    slideLabel: 'plates',
    slideText: 'There are **3 plates with letters and numbers**. Each letter is represented 16 times, which allows for the creation of any words.',
    slideImgSrc: 'images/highlights-section/swiper/plates.webp',
  },
  {
    slideLabel: 'bonuses',
    slideText: 'Each letter plate has **bonus elements**, such as a Unicoin keychain.',
    slideImgSrc: 'images/highlights-section/swiper/bonuses.webp',
  },
];
