import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  previewRoot: {
    position: 'relative',
    height: '100vh',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewTitleContainer: {
    textAlign: 'center',
    zIndex: 2,

    '& .subtitle': {
      marginTop: 50,
      fontSize: 'clamp(1.875rem, 0.2679rem + 1.7857vw, 2.5rem)',
      fontWeight: 400,
      color: 'white',

      '@media screen and (max-width: 1600px)': {
        marginTop: 40,
      },

      '@media screen and (max-width: 1200px)': {
        marginTop: 20,
        fontSize: 'clamp(1.25rem, 0.9208rem + 1.2723vw, 1.875rem)',
      },

      [theme.breakpoints.down('768')]: {
        whiteSpace: 'pre-line',
        lineHeight: 1.5,
      },
    },
  },
  previewPlayer: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,

    '& video': {
      objectFit: 'cover',
    },
  },
  previewPlayerThumbnail: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export default useStyles;
