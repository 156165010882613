import { type FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FreeMode,
  Mousewheel,
  Pagination,
} from 'swiper/modules';

import { SectionParagraph, SectionTitle } from 'components/ui';

import translations from 'locales/en/translations.json';

import { SLIDES } from './HighlightsSection.constants';

import useStyles from './HighlightsSection.styles';

const videoBlockUrl = 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/1dc56755a8dbace64e89e285a79d3836/manifest/video.m3u8';
interface HighlightsSectionProps {
  id?: string
}

const HighlightsSection: FC<HighlightsSectionProps> = ({ id }) => {
  const { cx, classes } = useStyles();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isSliderVideoLoaded, setIsSliderVideoLoaded] = useState(false);

  return (
    <section className={cx(classes.highlightsRoot, 'scrollSnapAlignStart')} id={id}>
      <div className={classes.highlightsSwiperBlock}>
        <div className={classes.highlightsTitleContainer}>
          <SectionTitle
            className="title"
            headingType="h2"
            textColor="black"
            headingSize="medium"
          >
            {translations.highlights_section__title}
          </SectionTitle>

          <div className="pagination" />
        </div>

        <Swiper
          className={classes.highlightsSwiper}
          slidesPerView="auto"
          spaceBetween={20}
          mousewheel={{
            enabled: true,
            forceToAxis: true,
          }}
          breakpoints={{
            0: {
              freeMode: { enabled: true },
            },
            769: {
              freeMode: { enabled: false },
            },
          }}
          pagination={{ clickable: true, el: '.pagination' }}
          modules={[
            Pagination,
            FreeMode,
            Mousewheel,
          ]}
        >
          {SLIDES.map(({
            slideLabel,
            slideText,
            slideImgSrc,
            slideVideoUrl,
          }) => (
            <SwiperSlide className={classes.highlightsSwiperSlide} key={slideImgSrc}>
              <SectionParagraph className="text">
                {slideText}
              </SectionParagraph>

              {(!isSliderVideoLoaded || !slideVideoUrl) && (
                <img
                  className="media"
                  width="100%"
                  height="100%"
                  src={slideImgSrc}
                  alt={slideLabel}
                />
              )}

              {slideVideoUrl && (
                <ReactPlayer
                  className={`media typeVideo ${slideLabel}`}
                  url={slideVideoUrl}
                  width="100%"
                  height="100%"
                  muted
                  loop
                  playing
                  playsinline
                  controls={false}
                  onBufferEnd={() => setIsSliderVideoLoaded(true)}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={classes.highlightsVideoBlock}>
        <SectionParagraph className="text">
          {translations.highlights_section__video_block_text}
        </SectionParagraph>

        <img
          className="image"
          src="images/highlights-section/lock.svg"
          alt="highlights"
        />

        <ReactPlayer
          className={classes.highlightsPlayer}
          url={videoBlockUrl}
          width="100%"
          height="100%"
          muted
          loop
          playing
          playsinline
          controls={false}
          onBufferEnd={() => setIsVideoLoaded(true)}
        />

        {!isVideoLoaded && (
          <img
            width="100%"
            height="100%"
            className={classes.highlightsPlayer}
            src="images/highlights-section/video-thumbnail.webp"
            alt="bearing"
          />
        )}
      </div>
    </section>
  );
};

export default HighlightsSection;
