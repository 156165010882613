// Unicorn ecosystem
export const UNICOIN_URL = 'https://unicoin.com';
export const UNICORN_HUNTERS_URL = 'https://unicornhunters.com';
export const UNICORN_NEWS_URL = 'https://unicorns.news';
export const UNICORN_STORE_URL = 'https://unicornstore.co';

// Unicoin socials
export const UNICOIN_TELEGRAM = 'https://t.me/Unicoin_OfficialTG';
export const UNICOIN_TIKTOK = 'https://www.tiktok.com/@unicoin_news';
export const UNICOIN_YOUTUBE = 'https://www.youtube.com/channel/UCtqDV69zsHa7j4ZBFjWfHwA/featured';
export const UNICOIN_VIMEO = 'https://vimeo.com/unicoin';
export const UNICOIN_INSTAGRAM = 'https://www.instagram.com/unicoin_news/';
export const UNICOIN_LINKEDIN = 'https://www.linkedin.com/company/unicoins/';
export const UNICOIN_X = 'https://twitter.com/Unicoin_News';
export const UNICOIN_FACEBOOK = 'https://www.facebook.com/UnicoinNews';

export const UNICOIN_PRIVACY_POLICY = 'https://unicoin.com/privacy';
