export const theme = {
  colors: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    brand: {
      yellow: '#FCD144',
      gray0: '#FAFAFA',
      gray100: '#EFEFEF',
      gray300: '#B3B3B3',
      gray350: '#B8B8B8',
      gray400: '#A3A3A3',
      gray450: '#858585',
      blue900: '#333D48',
      blue950: '#171C21',
    },
    status: {
      success: '#73E2A7',
      error: '#D16666',
    },
  },
  breakpoints: {
    values: {
      320: 320,
      360: 360,
      768: 768,
      1024: 1024,
      1200: 1200,
      1400: 1400,
      1600: 1600,
      1920: 1920,
    },
    down(
      key:
      '320'
      | '360'
      | '768'
      | '1024'
      | '1200'
      | '1400'
      | '1600'
      | '1920'
      | number,
      isHook?: boolean | undefined,
    ) {
      const value = typeof key === 'number' ? key : this.values[key];

      const hook = isHook || false;

      if (!hook) {
        return `@media (max-width: ${value}px)`;
      }
      return `(max-width: ${value}px)`;
    },
  },
};
