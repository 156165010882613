import { type FC } from 'react';

import {
  Header,
  SnapScrollContainer,
  StepsOverlay,
} from 'components/common';
import {
  FloatingBanner,
  HighlightsSection,
  InsertSection,
  OverviewSection,
  PackageSection,
  PreorderSection,
  PreviewSection,
  SealSection,
  SecureSection,
} from 'components';

import useStyles from './HomePage.styles';

const HomePage: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.homePage}>
      <Header />

      <FloatingBanner />

      <StepsOverlay />

      <SnapScrollContainer>
        <PreviewSection id="preview" />

        <OverviewSection id="overview" />

        <InsertSection id="insert" />

        <SealSection id="seal" />

        <SecureSection id="secure" />

        <div>
          <HighlightsSection id="highlights" />

          <PackageSection id="package" />
        </div>

        <PreorderSection id="preorder" />
      </SnapScrollContainer>
    </div>
  );
};

export default HomePage;
