import { type FC, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'usehooks-ts';

import { theme } from 'styles/theme';

import ContentLayout from 'components/layouts/ContentLayout';
import { SectionParagraph, SectionTitle } from 'components/ui';

import translations from 'locales/en/translations.json';

import useStyles from './OverviewSection.styles';

const overviewVideoUrl = 'https://customer-ymhdvkz81es8b0fz.cloudflarestream.com/1e14f357b03fa8adb59dc284ffc72348/manifest/video.m3u8';

interface OverviewSectionProps {
  id?: string,
}

const OverviewSection: FC<OverviewSectionProps> = ({ id }) => {
  const { classes } = useStyles();

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('768', true));

  return (
    <ContentLayout id={id}>
      <div className={classes.overviewInfoContainer}>
        <SectionTitle
          headingType="h2"
          className="title"
          headingSize="medium"
        >
          {translations.overview_section__title}
        </SectionTitle>

        {isMobile && (
          <div className={classes.overviewPlayerContainer}>
            <ReactPlayer
              className={classes.overviewPlayer}
              url={overviewVideoUrl}
              width="100%"
              height="100%"
              muted
              loop
              playing
              playsinline
              controls={false}
              onBufferEnd={() => setIsVideoLoaded(true)}
            />

            {!isVideoLoaded && (
              <img
                width="100%"
                height="100%"
                className={classes.overviewPlayer}
                src="images/overview-section/video-thumbnail.webp"
                alt="how it works"
              />
            )}
          </div>
        )}

        <SectionParagraph className="text">
          {translations.overview_section__paragraph_1}
        </SectionParagraph>

        <img
          className="image"
          src="images/overview-section/avocado.svg"
          alt={translations.overview_section__title}
        />

        <SectionParagraph className="text">
          {translations.overview_section__paragraph_2}
        </SectionParagraph>

        <img
          className="image"
          src="images/overview-section/dry.svg"
          alt={translations.overview_section__title}
        />
      </div>

      {!isMobile && (
        <div className={classes.overviewPlayerContainer}>
          <ReactPlayer
            className={classes.overviewPlayer}
            url={overviewVideoUrl}
            width="100%"
            height="100%"
            muted
            loop
            playing
            playsinline
            controls={false}
            onBufferEnd={() => setIsVideoLoaded(true)}
          />

          {!isVideoLoaded && (
            <img
              width="100%"
              height="100%"
              className={classes.overviewPlayer}
              src="images/overview-section/video-thumbnail.webp"
              alt="how it works"
            />
          )}
        </div>
      )}
    </ContentLayout>
  );
};

export default OverviewSection;
