import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  packageRoot: {
    minHeight: '100vh',
    height: 'unset',
    backgroundColor: theme.colors.brand.gray100,
    overflow: 'hidden',
  },
  packageContent: {
    height: '100%',
    gridTemplateColumns: '1fr 1fr',

    [theme.breakpoints.down('768')]: {
      gridTemplateColumns: '1fr',
    },

    [theme.breakpoints.down(400)]: {
      overflow: 'unset',
    },
  },
  packageTitle: {
    [theme.breakpoints.down('768')]: {
      marginBottom: 40,
    },
  },
  packageLeftBlock: {
    zIndex: 5,

    '& .text': {
      maxWidth: 418,

      [theme.breakpoints.down('768')]: {
        maxWidth: 'unset',
      },
    },
  },
  packageIncludesBlock: {
    '& .title': {
      margin: '80px 0 50px',
      fontSize: 'clamp(1.25rem, 1.087rem + 0.631vw, 1.875rem)',
      fontWeight: 600,

      [theme.breakpoints.down('1400')]: {
        margin: '50px 0 30px',
      },

      [theme.breakpoints.down('768')]: {
        margin: '10px 0 20px',
      },
    },

    '& .list': {
      display: 'flex',
      gap: 40,
      boxSizing: 'border-box',

      [theme.breakpoints.down('1400')]: {
        gap: 27,
      },

      [theme.breakpoints.down('360')]: {
        gap: 16,
      },

      '& .item': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 30,
        objectFit: 'contain',
        flexShrink: 1,

        [theme.breakpoints.down('768')]: {
          gap: 16,
        },

        '& .image': {
          height: 'auto',

          '&.unispin': {
            width: 'clamp(2.25rem, 2.136rem + 0.441vw, 2.688rem)',
          },

          '&.plates': {
            width: 'clamp(1.5rem, 0.864rem + 2.459vw, 3.938rem)',
          },

          '&.tweezers': {
            width: 'clamp(1rem, 0.935rem + 0.252vw, 1.25rem)',
          },

          '&.bearing': {
            width: 'clamp(2.063rem, 1.948rem + 0.441vw, 2.5rem)',
          },

          '&.key': {
            width: 'clamp(1.188rem, 1.139rem + 0.189vw, 1.375rem)',
          },
        },

        '& p': {
          [theme.breakpoints.down('768')]: {
            fontSize: 12,
          },
        },
      },
    },
  },
  packageImg: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 'auto',
    height: '100%',
    zIndex: 0,

    [theme.breakpoints.down('1200')]: {
      right: '-15%',
    },

    [theme.breakpoints.down('768')]: {
      position: 'static',
      inset: 'unset',
      width: '100vw',
      height: 'auto',
      marginInline: -16,
    },
  },
});

export default useStyles;
