import { type FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { theme } from 'styles/theme';

import ContentLayout from 'components/layouts/ContentLayout';
import { SectionParagraph, SectionTitle } from 'components/ui';

import translations from 'locales/en/translations.json';

import { INCLUDES_ITEMS } from './PackageSection.constants';

import useStyles from './PackageSection.styles';

interface PackageSectionProps {
  id?: string
}

const PackageSection: FC<PackageSectionProps> = ({ id }) => {
  const { cx, classes } = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down('768', true));

  return (
    <ContentLayout
      classNames={{
        root: classes.packageRoot,
        content: classes.packageContent,
      }}
      id={id}
    >
      <div className={classes.packageLeftBlock}>
        <SectionTitle
          className={classes.packageTitle}
          headingType="h2"
          textColor="black"
          headingSize="medium"
        >
          {translations.package_section__title}
        </SectionTitle>

        <SectionParagraph className="text" textColor="black">
          {translations.package_section__paragraph}
        </SectionParagraph>

        {isMobile && (
          <img
            className={classes.packageImg}
            src="images/package-section/box-mobile.webp"
            alt={translations.package_section__title}
          />
        )}

        <div className={classes.packageIncludesBlock}>
          <h3 className="title">
            {translations.package_section__set_title}
          </h3>

          <div className="list">
            {INCLUDES_ITEMS.map(({
              imgSrc,
              text,
              label,
              imgSrcMobile,
              textMobile,
            }) => (
              <div className="item" key={imgSrc}>
                <img
                  className={cx('image', label)}
                  src={(isMobile && imgSrcMobile) ? imgSrcMobile : imgSrc}
                  alt={text}
                />

                <p>
                  {(isMobile && textMobile) ? textMobile : text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {!isMobile && (
        <img
          className={classes.packageImg}
          src="images/package-section/box.webp"
          alt={translations.package_section__title}
        />
      )}
    </ContentLayout>
  );
};

export default PackageSection;
