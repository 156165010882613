import {
  type FC,
  useEffect,
  useState,
} from 'react';
import { Checkbox } from 'react-aria-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ContentLayout from 'components/layouts/ContentLayout';
import { SectionParagraph, SectionTitle } from 'components/ui';

import preorderFormSchema from 'schemas/preorderFormSchema';

import translations from 'locales/en/translations.json';

import { PREORDER_WEBHOOK } from 'constants/app';
import { UNICOIN_PRIVACY_POLICY } from 'constants/links';

import { SOCIALS } from './PreorderSection.constants';

import useStyles from './PreorderSection.styles';

interface PreorderSectionProps {
  id?: string
}

const PreorderSection: FC<PreorderSectionProps> = ({ id }) => {
  const { cx, classes } = useStyles();

  const [submitStatus, setSubmitStatus] = useState<'success' | 'failed' | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const preorderForm = useForm({
    defaultValues: {
      name: '',
      email: '',
      agreedToPrivacyPolicy: 'false',
    },
    resolver: yupResolver(preorderFormSchema),
  });

  const onSubmit = () => {
    if (!PREORDER_WEBHOOK) return console.log('Failed to find a webhook for sending form data');

    const formData = preorderForm.getValues();

    setIsButtonDisabled(true);

    fetch(PREORDER_WEBHOOK, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        console.log('Request status:', res.status);

        return res.json();
      })
      .then(() => {
        setSubmitStatus('success');

        preorderForm.reset();
      })
      .catch((err) => {
        setSubmitStatus('failed');

        console.log(err);
      });
  };

  useEffect(() => {
    let resetSubmitStatusTimeout: NodeJS.Timeout;

    if (submitStatus) {
      resetSubmitStatusTimeout = setTimeout(() => {
        setSubmitStatus(null);

        if (isButtonDisabled) setIsButtonDisabled(false);
      }, 2000);
    }

    return () => clearTimeout(resetSubmitStatusTimeout);
  }, [submitStatus]);

  return (
    <ContentLayout classNames={{ root: cx(classes.preorderRoot, 'scrollSnapAlignStart'), content: classes.preorderContent }} id={id}>
      <SectionTitle
        className={classes.preorderTitle}
        headingSize="medium"
        headingType="h2"
      >
        {translations.preorder_section__title}
      </SectionTitle>

      <div className={classes.preorderTextBlock}>
        <div className="priceContainer">
          <p className="price">
            {translations.preorder_section__price}
          </p>

          <div className="mobileTeaserWrapper">
            <p className="mobileTeaser">
              {translations.preorder_section__mobile_teaser}
            </p>
          </div>
        </div>

        <h3 className="subtitle">
          {translations.preorder_section__subtitle}
        </h3>

        <SectionParagraph className="text">
          {translations.preorder_section__text}
        </SectionParagraph>
      </div>

      <form className={classes.preorderForm} onSubmit={preorderForm.handleSubmit(onSubmit)}>
        <input
          className={classes.preorderTextInput}
          type="text"
          placeholder={translations.preorder_section__input_placeholder_name}
          {...preorderForm.register('name')}
        />

        <input
          className={classes.preorderTextInput}
          type="email"
          placeholder={translations.preorder_section__input_placeholder_email}
          {...preorderForm.register('email')}
        />

        <Controller
          name="agreedToPrivacyPolicy"
          control={preorderForm.control}
          render={({ field }) => (
            <div className={classes.preorderCheckboxWrapper}>
              <Checkbox className={classes.preorderCheckboxContainer} {...field}>
                <div className="checkbox">
                  <svg className="checkmark" viewBox="0 0 18 18" aria-hidden="true">
                    <polyline points="1 9 7 14 15 4" />
                  </svg>
                </div>

                <p className="label">
                  {translations.preorder_section__input_checkbox}
                    &nbsp;
                </p>
              </Checkbox>
              <a
                className="privacyPolicyLink"
                href={UNICOIN_PRIVACY_POLICY}
                target="_blank"
                rel="noreferrer"
              >
                {translations.preorder_section__input_checkbox_link}
              </a>
            </div>
          )}
        />

        <button
          className={cx(classes.preorderButton, submitStatus && submitStatus)}
          type="submit"
          aria-label="Submit"
          disabled={(!preorderForm.formState.isValid || isButtonDisabled)}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {submitStatus ? (submitStatus === 'success' ? 'Thank you!' : 'Request failed') : translations.preorder_section__input_button}
        </button>
      </form>

      <div className={classes.preorderFooter}>
        <p className="copyright">
          &#169;&nbsp;
          {translations.preorder_section__footer}
        </p>

        <div className="socialLinks">
          {SOCIALS.map(({
            label,
            logoSrc,
            link,
          }) => (
            <a
              key={logoSrc}
              href={link}
              target="_blank"
              rel="noreferrer"
              aria-label={`${label} link`}
            >
              <img src={logoSrc} alt={label} />
            </a>
          ))}
        </div>
      </div>
    </ContentLayout>
  );
};

export default PreorderSection;
