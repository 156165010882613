import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  highlightsRoot: {
    position: 'relative',
    padding: '200px 50px 80px',
    backgroundColor: 'white',
    overflow: 'hidden',

    [theme.breakpoints.down('768')]: {
      padding: '100px 16px',
    },
  },
  highlightsSwiperBlock: {
    position: 'relative',

    [theme.breakpoints.down('768')]: {
      paddingBottom: 30,
    },
  },
  highlightsTitleContainer: {
    maxWidth: 1800,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down(2100)]: {
      maxWidth: 1100,
    },

    [theme.breakpoints.down('1400')]: {
      width: 'unset',
    },

    '& .title': {
      marginBottom: 0,
    },

    '& .pagination': {
      display: 'flex',
      gap: 20,

      '&.swiper-pagination-horizontal': {
        width: 'max-content',
      },

      [theme.breakpoints.down('768')]: {
        position: 'absolute',
        top: '100%',
        left: 0,
        gap: 16,
      },

      '& .swiper-pagination-bullet': {
        width: 8,
        height: 8,
        margin: 0,
        backgroundColor: theme.colors.brand.gray300,
        borderRadius: 5,
        opacity: 1,
        transition: '0.3s ease-out',
        cursor: 'pointer',

        [theme.breakpoints.down('768')]: {
          width: 4,
          height: 4,
        },

        '&.swiper-pagination-bullet-active': {
          width: 70,
          backgroundColor: 'black',
          borderRadius: 5,
        },
      },
    },
  },
  highlightsSwiper: {
    paddingTop: 60,
    overflow: 'unset',

    [theme.breakpoints.down('1200')]: {
      paddingTop: 30,
    },
  },
  highlightsSwiperSlide: {
    position: 'relative',
    width: 'clamp(18.5rem, 10.604rem + 30.517vw, 48.75rem)',
    height: 'clamp(18.75rem, 14.002rem + 18.348vw, 36.938rem)',
    backgroundColor: theme.colors.common.black,
    borderRadius: 10,
    overflow: 'hidden',

    '& .text': {
      maxWidth: '80%',
      marginTop: 50,
      marginLeft: 50,

      [theme.breakpoints.down('1600')]: {
        marginTop: 40,
        marginLeft: 40,
      },

      [theme.breakpoints.down('1024')]: {
        marginTop: 20,
        marginLeft: 20,
      },
    },

    '& .media': {
      position: 'absolute',
      inset: 0,
      objectFit: 'cover',
      zIndex: -1,

      '&.typeVideo': {
        top: 'unset',
        left: '50%',
        right: 'unset',
        bottom: 0,
        width: 'clamp(18.125rem, 15.025rem + 11.98vw, 30rem) !important',
        height: 'clamp(14.375rem, 10.9rem + 13.43vw, 27.688rem) !important',
        transform: 'translateX(-50%)',

        '& video': {
          objectFit: 'contain',
        },

        '&.kit': {
          '& video': {
            objectFit: 'cover',
          },
        },
      },
    },
  },
  highlightsVideoBlock: {
    position: 'relative',
    height: 'clamp(43.75rem, 0rem + 50vw, 62.5rem)',
    marginTop: 60,
    backgroundColor: 'black',
    borderRadius: 10,
    overflow: 'hidden',

    [theme.breakpoints.down('1600')]: {
      marginTop: 40,
    },

    [theme.breakpoints.down('1400')]: {
      height: 'clamp(20.438rem, 10.649rem + 37.83vw, 43.75rem)',
    },

    [theme.breakpoints.down('768')]: {
      marginTop: 50,
    },

    '& .text': {
      position: 'relative',
      maxWidth: 457,
      marginTop: 65,
      marginLeft: 50,
      display: 'inline-block',
      zIndex: 2,

      [theme.breakpoints.down('1600')]: {
        marginTop: 40,
        marginLeft: 40,
      },

      [theme.breakpoints.down('1024')]: {
        marginTop: 20,
        marginLeft: 20,
      },
    },

    '& .image': {
      position: 'absolute',
      left: '50%',
      bottom: 'clamp(1.875rem, 0.244rem + 6.305vw, 8.125rem)',
      width: 'clamp(2.5rem, 1.456rem + 4.035vw, 6.5rem)',
      height: 'auto',
      transform: 'translateX(-50%)',
      zIndex: 2,
    },
  },
  highlightsPlayer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '100%',
    width: 'clamp(22.375rem, 5.685rem + 64.502vw, 86.313rem) !important',
    height: 'auto !important',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
});

export default useStyles;
