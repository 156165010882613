import { theme } from 'styles/theme';
import { tss } from 'tss-react';

const useStyles = tss.create({
  burgerMenuRoot: {
    position: 'absolute',
    paddingBottom: 20,
    top: '99%',
    left: 0,
    width: '100%',
    display: 'block',
    backgroundColor: theme.colors.brand.gray0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    transition: '0.4s ease',
    overflow: 'hidden',
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateY(-5%)',
    transformOrigin: 'top',
    zIndex: -1,

    '&.open': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0%)',
    },

    '& .contentWrapper': {
      maxHeight: 'calc(100dvh - 50px - 20px - 16px - 20px)',
      overflow: 'scroll',

      '& .navigationMenu': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.colors.common.white,
      },
    },
  },
  burgerNavButton: {
    marginTop: 30,
    fontSize: 24,
    color: theme.colors.common.black,
  },
  burgerMenuEcosystemContainer: {
    width: '100%',
    marginTop: 30,
    paddingTop: 20,
    textAlign: 'center',
    backgroundColor: theme.colors.brand.gray0,

    '& .title': {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: theme.colors.brand.gray350,
    },

    '& .list': {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,

      '& .link': {
        fontSize: 20,
        color: theme.colors.common.black,
      }
      ,
    },
  },
});

export default useStyles;
