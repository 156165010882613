import {
  type FC,
  useEffect,
  useState,
} from 'react';

import translations from 'locales/en/translations.json';

import { useMediaQuery } from 'usehooks-ts';
import { theme } from 'styles/theme';
import useStyles from './FloatingBanner.styles';

const FloatingBanner: FC = () => {
  const { cx, classes } = useStyles();

  const [isHidden, setIsHidden] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('768', true));

  useEffect(() => {
    const section = document.getElementById('preorder');

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      setIsHidden(entry.isIntersecting);
    }, { threshold: 0.2 });

    if (section) {
      observer.observe(section);
    }

    return () => observer.disconnect();
  }, []);

  const scrollTo = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={cx(classes.floatingBannerRoot, isHidden && 'hidden')}>
      <p className={classes.floatingBannerPrice}>
        {translations.preorder_section__price}
      </p>

      <p className={classes.floatingBannerText}>
        {translations.floating_banner__text}
      </p>

      <button
        className={classes.floatingBannerButton}
        onClick={() => scrollTo('preorder')}
        type="button"
      >
        {isMobile
          ? translations.floating_banner__button_mobile
          : translations.floating_banner__button}
      </button>
    </div>
  );
};

export default FloatingBanner;
